<template>
  <div class="wrap x-x">
    <cardTitleCom card-title="交班信息" style="width: 400px" class="info">
      <template slot="rightCardTitle">
        <el-radio-group v-model="curTab" size="mini" class="marR10">
          <el-radio-button label="payment">交班对账单</el-radio-button>
          <el-radio-button label="goods">商品对账单</el-radio-button>
        </el-radio-group>
      </template>
      <template slot="cardContent">
        <div class="statementOfAccount-detail" v-if="orderContent.payMoneyInfo">
          <el-scrollbar style="height: 700px">
            <!-- 交班对账单 -->
            <div class="shifts" v-if="curTab === 'payment'" id="payment">
              <div class="title">交班对账单</div>
              <div class="content">
                <el-scrollbar>
                  <!-- 收银员明细 -->
                  <div class="cashier-detail">
                    <div class="row">
                      <div class="label w70">店名:</div>
                      <div class="val">【{{ orderContent.shopName }}】</div>
                    </div>
                    <div class="row">
                      <div class="label w70">日期:</div>
                      <div class="val">{{ orderContent.date }}</div>
                    </div>
                    <div class="row">
                      <div class="label w70">收银台:</div>
                      <div class="val">{{ orderContent.machineName }}</div>
                    </div>
                    <div class="row">
                      <div class="label w70">收银员:</div>
                      <div class="val">{{ orderContent.cashierName }}</div>
                    </div>
                    <div class="row">
                      <div class="label w70">打印时间:</div>
                      <div class="val">
                        {{ orderContent.printPayCheckTime }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="label w70">首笔小票:</div>
                      <div class="val">
                        {{ orderContent.firstReceiptTime }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="label w70">末笔小票:</div>
                      <div class="val">
                        {{ orderContent.lastReceiptTime }}
                      </div>
                    </div>
                  </div>
                  <!-- 销售金额明细 -->
                  <div class="salesAmount-detail">
                    <div
                      class="row"
                      v-if="
                        orderContent.payMoneyInfo && orderContent.payMoneyInfo.totalMoney
                      "
                    >
                      <div class="label w70">交易总额:</div>
                      <div class="val">
                        {{
                          orderContent.payMoneyInfo
                            ? orderContent.payMoneyInfo.totalMoney
                            : ""
                        }}
                      </div>
                    </div>
                    <div
                      class="row"
                      v-if="
                        orderContent.payMoneyInfo &&
                        orderContent.payMoneyInfo.revenueMoney
                      "
                    >
                      <div class="label w70">营收金额:</div>
                      <div class="val">
                        {{
                          `${
                            orderContent.payMoneyInfo
                              ? orderContent.payMoneyInfo.revenueMoney
                              : ""
                          }(*代表营收)`
                        }}
                      </div>
                    </div>
                    <div
                      class="row"
                      v-if="
                        orderContent.payMoneyInfo && orderContent.payMoneyInfo.dealTotal
                      "
                    >
                      <div class="label w70">交易笔数:</div>
                      <div class="val">
                        {{
                          orderContent.payMoneyInfo
                            ? orderContent.payMoneyInfo.dealTotal
                            : ""
                        }}
                      </div>
                    </div>
                    <div
                      class="row"
                      v-if="
                        orderContent.payMoneyInfo.salesCollectionMoney ||
                        orderContent.payMoneyInfo.salesRefundMoney
                      "
                    >
                      <div class="label w70">销售金额:</div>
                      <div class="val">
                        {{
                          `${
                            orderContent.payMoneyInfo
                              ? orderContent.payMoneyInfo.collectionMoneyTotal
                              : ""
                          }(交易${
                            orderContent.payMoneyInfo
                              ? orderContent.payMoneyInfo.salesCollectionMoney
                              : ""
                          },退货${
                            orderContent.payMoneyInfo
                              ? orderContent.payMoneyInfo.salesRefundMoney
                              : ""
                          })`
                        }}
                      </div>
                    </div>
                    <div
                      class="row"
                      v-if="
                        orderContent.payMoneyInfo.bookCollectionMoney ||
                        orderContent.payMoneyInfo.bookRefundMoney
                      "
                    >
                      <div class="label w70">预订金额:</div>
                      <div class="val">
                        {{
                          `${
                            orderContent.payMoneyInfo
                              ? orderContent.payMoneyInfo.bookMoneyTotal
                              : ""
                          }(交易${
                            orderContent.payMoneyInfo
                              ? orderContent.payMoneyInfo.bookCollectionMoney
                              : ""
                          },退款${
                            orderContent.payMoneyInfo
                              ? orderContent.payMoneyInfo.bookRefundMoney
                              : ""
                          })`
                        }}
                      </div>
                    </div>
                    <div
                      class="row"
                      v-if="
                        orderContent.payMoneyInfo.rechargeCollectionMoney ||
                        orderContent.payMoneyInfo.rechargeRefundCollectionMoney
                      "
                    >
                      <div class="label w70">充值金额:</div>
                      <div class="val">
                        {{
                          `${orderContent.payMoneyInfo.rechargeCollectionTotal}(交易${orderContent.payMoneyInfo.rechargeCollectionMoney},退款${orderContent.payMoneyInfo.rechargeRefundCollectionMoney})`
                        }}
                      </div>
                    </div>
                  </div>
                  <!-- 收款明细 -->
                  <div class="Collection-detail">
                    <div
                      class="row"
                      v-if="
                        orderContent.payMoneyInfo &&
                        orderContent.payMoneyInfo.salesCollectionNum
                      "
                    >
                      <strong class="label w120">销售收款金额:</strong>
                      <strong class="val">{{
                        `${
                          orderContent.payMoneyInfo
                            ? orderContent.payMoneyInfo.salesCollectionMoney
                            : ""
                        } (${
                          orderContent.payMoneyInfo
                            ? orderContent.payMoneyInfo.salesCollectionNum
                            : ""
                        }笔)`
                      }}</strong>
                    </div>
                    <div
                      v-for="item in orderContent.payMoneyInfo.salesCollectionDetails"
                      :key="item.uuid"
                    >
                      <div class="row notBold">
                        <div class="label w120">
                          {{ `${item.isRevenue ? "*" : ""}${item.payModeName}:` }}
                        </div>
                        <div class="val">{{ item.payMoney }}</div>
                      </div>
                      <div
                        class="row-children"
                        v-for="item_val in item.depositDetails"
                        :key="item_val.uuid"
                      >
                        <div class="label w120">
                          {{ `${item_val.sourceOrderTypeName}:` }}
                        </div>
                        <div class="val">{{ item_val.payMoney }}</div>
                      </div>
                    </div>
                    <div
                      class="row"
                      v-if="
                        orderContent.payMoneyInfo &&
                        orderContent.payMoneyInfo.salesRefundNum
                      "
                    >
                      <strong class="label w120">销售退款金额:</strong>
                      <strong class="val">{{
                        `${orderContent.payMoneyInfo.salesRefundMoney} (${orderContent.payMoneyInfo.salesRefundNum}笔)`
                      }}</strong>
                    </div>
                    <div
                      class="row notBold"
                      v-for="item in orderContent.payMoneyInfo.salesRefundDetails"
                      :key="item.uuid"
                    >
                      <div class="label w120">
                        {{ `${item.isRevenue ? "*" : ""}${item.payModeName}:` }}
                      </div>
                      <div class="val">{{ item.payMoney }}</div>
                    </div>
                    <div
                      class="row"
                      v-if="
                        orderContent.payMoneyInfo &&
                        orderContent.payMoneyInfo.bookCollectionNum
                      "
                    >
                      <strong class="label w120">预定金收款金额:</strong>
                      <strong class="val">{{
                        `${orderContent.payMoneyInfo.bookCollectionMoney} (${orderContent.payMoneyInfo.bookCollectionNum}笔)`
                      }}</strong>
                    </div>
                    <div
                      class="row notBold"
                      v-for="item in orderContent.payMoneyInfo.bookCollectionDetails"
                      :key="item.uuid"
                    >
                      <div class="label w120">
                        {{ `${item.isRevenue ? "*" : ""}${item.payModeName}:` }}
                      </div>
                      <div class="val">{{ item.payMoney }}</div>
                    </div>
                    <div
                      class="row"
                      v-if="
                        orderContent.payMoneyInfo &&
                        orderContent.payMoneyInfo.bookRefundNum
                      "
                    >
                      <strong class="label w120">预定金退款金额:</strong>
                      <strong class="val">{{
                        `${orderContent.payMoneyInfo.bookRefundMoney} (${orderContent.payMoneyInfo.bookRefundNum}笔)`
                      }}</strong>
                    </div>
                    <div
                      class="row notBold"
                      v-for="item in orderContent.payMoneyInfo.bookRefundDetails"
                      :key="item.uuid"
                    >
                      <div class="label w120">
                        {{ `${item.isRevenue ? "*" : ""}${item.payModeName}:` }}
                      </div>
                      <div class="val">{{ item.payMoney }}</div>
                    </div>
                    <div
                      class="row"
                      v-if="
                        orderContent.payMoneyInfo &&
                        orderContent.payMoneyInfo.rechargeCollectionNum
                      "
                    >
                      <strong class="label w120">充值收款金额:</strong>
                      <strong class="val">{{
                        `${orderContent.payMoneyInfo.rechargeCollectionMoney} (${orderContent.payMoneyInfo.rechargeCollectionNum}笔)`
                      }}</strong>
                    </div>
                    <div
                      class="row notBold"
                      v-for="item in orderContent.payMoneyInfo.rechargeCollectionDetails"
                      :key="item.uuid"
                    >
                      <div class="label w120">
                        {{ `${item.isRevenue ? "*" : ""}${item.payModeName}:` }}
                      </div>
                      <div class="val">{{ item.payMoney }}</div>
                    </div>
                    <div
                      class="row"
                      v-if="
                        orderContent.payMoneyInfo.rechargeRefundCollectionMoney ||
                        orderContent.payMoneyInfo.rechargeRefundCollectionNum ||
                        orderContent.payMoneyInfo.rechargeRefundCollectionDetails
                      "
                    >
                      <strong class="label w120">充值退款金额:</strong>
                      <strong class="val">
                        {{
                          `${orderContent.payMoneyInfo.rechargeGiveMoney}(赠送${orderContent.payMoneyInfo.rechargeNormalGiveMoney},退赠${orderContent.payMoneyInfo.rechargeRefundGiveMoney})`
                        }}
                      </strong>
                    </div>
                    <div
                      class="row notBold"
                      v-for="item in orderContent.payMoneyInfo
                        .rechargeRefundCollectionDetails"
                      :key="item.uuid"
                    >
                      <div class="label w120">
                        {{ `${item.isRevenue ? "*" : ""}${item.payModeName}:` }}
                      </div>
                      <div class="val">{{ item.payMoney }}</div>
                    </div>
                    <div
                      class="row"
                      v-if="
                        orderContent.payMoneyInfo &&
                        orderContent.payMoneyInfo.rechargeGiveMoney
                      "
                    >
                      <strong class="label w120">充值赠送金额:</strong>
                      <strong class="val">{{
                        `${orderContent.payMoneyInfo.rechargeGiveMoney}`
                      }}</strong>
                    </div>
                    <div
                      class="row notBold"
                      v-for="item in orderContent.payMoneyInfo.rechargeGiveDetails"
                      :key="item.uuid"
                    >
                      <div class="label w120">
                        {{ `${item.isRevenue ? "*" : ""}${item.payModeName}:` }}
                      </div>
                      <div class="val">{{ item.payMoney }}</div>
                    </div>
                  </div>
                  <!-- 付款方式明细 -->
                  <div class="payMethod-detail">
                    <div class="row">
                      <div class="label w120">付款方式汇总</div>
                    </div>
                    <div
                      class="row notBold"
                      v-for="(item, i) in orderContent.payMoneyInfo
                        .payMethodCollectDetails"
                      :key="`${item.payModeId}.${i}`"
                    >
                      <div class="label w120">
                        {{ `${item.isRevenue ? "*" : ""}${item.payModeName}:` }}
                      </div>
                      <div class="val">{{ item.payMoney }}</div>
                    </div>
                  </div>
                  <!-- 总数 -->
                  <div
                    class="total"
                    v-if="
                      orderContent.payMoneyInfo &&
                      orderContent.payMoneyInfo.TemporaryDiscount
                    "
                  >
                    <div class="row">
                      <div class="label w105">临时优惠金额:</div>
                      <div class="val">
                        {{ orderContent.payMoneyInfo.TemporaryDiscount }}
                      </div>
                    </div>
                    <div
                      class="row"
                      v-if="
                        orderContent.payMoneyInfo && orderContent.payMoneyInfo.cashTatal
                      "
                    >
                      <div class="label w105">应缴现金:</div>
                      <div class="val">
                        {{ orderContent.payMoneyInfo.cashTatal }}
                      </div>
                    </div>
                  </div>
                  <!-- 签字 -->
                  <div class="signature">
                    <div class="row w85">
                      <div class="label">交款人签字:</div>
                      <div class="val"></div>
                    </div>
                    <div class="row w85">
                      <div class="label">收款人签字:</div>
                      <div class="val"></div>
                    </div>
                    <div class="row">
                      <div class="label w85 remark">备注:</div>
                      <div class="val">
                        {{ orderContent.payMoneyInfo.remark }}
                      </div>
                    </div>
                  </div>
                  <!-- 店铺名称 -->
                  <div class="shopName">【深圳市隼云科技软件】</div>
                </el-scrollbar>
              </div>
            </div>
            <!-- 商品对账单 -->
            <div class="goods" v-else id="goods">
              <div class="title">商品对账单</div>
              <div class="content">
                <el-scrollbar>
                  <!-- 收银员明细 -->
                  <div class="cashier-detail">
                    <div class="row">
                      <div class="label w77">店名</div>
                      :
                      <div class="val">【{{ orderContent.shopName }}】</div>
                    </div>
                    <div class="row">
                      <div class="label w77">日期</div>
                      :
                      <div class="val">{{ orderContent.date }}</div>
                    </div>
                    <div class="row">
                      <div class="label w77">收银台</div>
                      :
                      <div class="val">{{ orderContent.machineName }}</div>
                    </div>
                    <div class="row">
                      <div class="label w77">收银员</div>
                      :
                      <div class="val">{{ orderContent.cashierName }}</div>
                    </div>
                    <div class="row">
                      <div class="label w77">打印时间</div>
                      :
                      <div class="val">
                        {{ orderContent.printGoodsCheckTime }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="label w77">首笔小票</div>
                      :
                      <div class="val">
                        {{ orderContent.firstReceiptTime }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="label w77">末笔小票</div>
                      :
                      <div class="val">
                        {{ orderContent.lastReceiptTime }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="label w77">备注</div>
                      :
                      <div class="val">
                        {{ orderContent.goodsInfo.remark }}
                      </div>
                    </div>
                  </div>
                  <!-- 商品明细 -->
                  <div class="goods-detail">
                    <!-- 标题 -->
                    <div class="goodsTitle-list">
                      <strong class="nameTitle"> 品名 </strong>
                      <strong class="amountTitle"> 数量 </strong>
                      <strong class="salePriceTitle"> 售价 </strong>
                      <strong class="totalTitle"> 小计 </strong>
                    </div>
                    <!-- 商品 -->
                    <div class="goodsList">
                      <div
                        class="goodsItem"
                        v-for="item in orderContent.goodsInfo.goodsDetails"
                        :key="item.uuid"
                      >
                        <div class="goodsName">{{ item.goodsName }}</div>
                        <div class="goodsRow x-f">
                          <div class="goodsNo">{{ item.goodsNo }}</div>
                          <div class="goodsAmount">
                            {{ `${item.unitQty}(${item.unitName})` }}
                          </div>
                          <div class="goodsSalePrice">{{ item.unitPrice }}</div>
                          <div class="goodsTotal">{{ item.unitMoney }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-scrollbar>
              </div>
              <div class="footer-total">
                <strong class="label">合计:</strong>
                <strong class="amount">{{ orderContent.goodsInfo.goodsTotalQty }}</strong>
                <strong class="totalPrices">{{
                  `¥${orderContent.goodsInfo.goodsTotalMoney}`
                }}</strong>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </template>
    </cardTitleCom>
    <TablePage
      style="width: calc(100% - 420px)"
      ref="tablePage"
      v-model="options"
      @handleEvent="handleEvent"
    />
  </div>
</template>
<script>
import cardTitleCom from "@/views/components/cardTitleCom"; // 卡片标题
import TablePage from "@/components/tablePage";
import { getDetail, getDetailSummary } from "@/api/shop/report/posHandover.js"; //销售完成比查询
export default {
  name: "posHandover",
  components: { cardTitleCom, TablePage },
  data() {
    return {
      curTab: "payment",
      orderContent: {},
      getNumber: (data, payModeId) =>
        data?.find((x) => x.payModeId === payModeId)?.payMoney || 0,
      options: {
        summary: ["totalPayMoney"],
        listNo: true,
        loading: true,
        radioSelect: true,
        uuid: "0cf180d5-d34d-cb09-392b-4b435e3fca8f",
        pagination: {
          total: 0,
          page: 1,
          size: 10,
        },
        radioObject: this.options?.list[0],
        radio: this.options?.list[0],
        getListApi: getDetail,
        body: {},
        title: "门店收银交班查询",
        getSummaryApi: getDetailSummary,
        rowKey: "orderLineId",
        search: [
          {
            label: "交班时间",
            type: "datetimerange",
            model: "",
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          this.$select({
            key: "listShop",
            option: {
              filter: "shopIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择门店",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "shop" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listCashier",
            option: {
              filter: "cashierIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择收银员",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "cashier" }),
                    },
                  },
                ],
              },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        columns: [
          {
            prop: "shopNo",
            label: "门店编号",
            minWidth: 120,
          },
          {
            prop: "shopName",
            label: "门店名称",
            minWidth: 120,
          },
          {
            prop: "cashierNo",
            label: "收银员编号",
            minWidth: 100,
          },
          {
            prop: "cashierName",
            label: "收银员名称",
            minWidth: 100,
          },
          {
            prop: "endTime",
            label: "交班时间",
            minWidth: 155,
          },
          {
            prop: "firstTime",
            label: "首笔交易时间",
            minWidth: 155,
          },
          {
            prop: "lastTime",
            label: "末笔交易时间",
            minWidth: 155,
          },
          {
            prop: "totalPayMoney",
            label: "总交易金额",
            minWidth: 100,
          },
          {
            prop: "payTypse",
            align: "center",
            label: "收款方式",
            children: () =>
              this.options.list?.[0]?.payTypes?.map?.((x) => ({
                ...x,
                label: x.payModeName,
                prop: `payDetailList.${x.payModeId}.payModeId.payMoney`,
                minWidth: 110,
                summary: true,
                getValue: (row, prop) =>
                  row.payDetailList?.find((y) => y.payModeId === x.payModeId)?.payMoney ||
                  0,
              })) || [],
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "rowClick":
          this.orderContent = JSON.parse(this.options.radioObject.orderIndexContent);
          console.log("orderContent", this.orderContent);
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  background-color: #e7e7e7;
  .info {
    margin: 10px 0 0 0;
    // 对账单详情
    .statementOfAccount-detail {
      width: 100%;
      height: 100%;
      background: url(~@/assets/fixedImages/receipt-bg.png);
      background-size: 100% 100%;
      padding-bottom: 15px;
      .w77 {
        width: 77px;
      }
      //交班对账单
      .shifts {
        height: 100%;
        //头部标题
        .title {
          height: 6%;
          border-bottom: 1px dashed #e5e5e5;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
        }
        .content {
          text-align: left;
          height: 93%;
          padding: 0 3px;

          .el-scrollbar {
            height: 100%;
            overflow: hidden;
            ::v-deep .el-scrollbar__wrap {
              overflow-x: hidden;
            }
            //收银员明细 / 总数 /签字
            .cashier-detail,
            .total,
            .signature {
              padding: 8px;
              border-bottom: 1px solid #c1c1c1;
              .row {
                line-height: 25px;
                display: flex;
                font-weight: bold;
                .label {
                  text-align: justify;
                  text-align-last: justify;
                  &.w70 {
                    width: 70px;
                  }
                  &.w85 {
                    width: 85px;
                  }
                }
                .val {
                  margin-left: 5px;
                }
              }
            }
            //销售金额明细 //收款明细
            .salesAmount-detail,
            .Collection-detail,
            .payMethod-detail {
              padding: 8px;
              border-bottom: 1px solid #c1c1c1;
              .row {
                line-height: 25px;
                display: flex;
                &.notBold {
                  font-weight: 400;
                }
                .label {
                  text-align: right;
                  &.w70 {
                    width: 70px;
                  }
                  &.w105 {
                    width: 105px;
                  }
                  &.w120 {
                    width: 120px;
                  }
                }
                .val {
                  margin-left: 5px;
                }
              }
              .row-children {
                font-weight: 400;
                font-size: 12px;
                display: flex;
                align-items: center;
                .label {
                  text-align: right;
                  width: 140px;
                }
                .val {
                  margin-left: 5px;
                }
              }
            }
            //店铺名称
            .shopName {
              padding: 8px;
              height: 100px;
            }
          }
        }
      }
      //商品对账单
      .goods {
        height: 100%;
        padding: 0 3px;
        //头部标题
        .title {
          height: 6%;
          border-bottom: 1px dashed #e5e5e5;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
        }
        .content {
          height: 87%;
          .el-scrollbar {
            height: 100%;
            overflow: hidden;
            ::v-deep .el-scrollbar__wrap {
              overflow-x: hidden;
            }
            //收银员明细
            .cashier-detail {
              padding: 8px;
              border-bottom: 1px solid #c1c1c1;
              .row {
                line-height: 25px;
                display: flex;
                .label {
                  text-align: justify;
                  text-align-last: justify;
                  width: 77px;
                }
                .val {
                  margin-left: 5px;
                }
              }
            }
            //商品明细
            .goods-detail {
              padding: 8px;
              // 标题
              .goodsTitle-list {
                display: flex;
                line-height: 40px;
                .nameTitle {
                  width: 40%;
                  text-align: left;
                }
                .amountTitle,
                .salePriceTitle,
                .totalTitle {
                  width: 20%;
                }
              }
              //商品列表
              .goodsList {
                .goodsItem {
                  margin: 5px 0;
                  .goodsName {
                    text-align: left;
                  }
                  .goodsNo {
                    width: 40%;
                    text-align: left;
                  }
                  .goodsAmount,
                  .goodsSalePrice,
                  .goodsTotal {
                    width: 20%;
                  }
                }
              }
            }
          }
        }
        .footer-total {
          height: 7%;
          display: flex;
          align-items: center;
          border-top: 1px dashed #e5e5e5;
          padding: 10px 0;
          .label {
            width: 60%;
            text-align: left;
            padding-left: 5%;
          }
          .amount,
          .salePrices {
            width: 20%;
          }
        }
      }
    }
  }
}
</style>
